import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/shared/Sidebar/Sidebar';
import { AdminListUsers, AdminDeleteUser } from '../../http/index';
import toast, { Toaster } from 'react-hot-toast';
import { CiFilter } from 'react-icons/ci';
import { Dropdown, Menu } from 'antd';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { GoUnverified } from 'react-icons/go';
import AdminNavbar from '../../components/shared/Navbar/AdminNavbar';
import SendMail from '../../components/AdminComponents/SendMailToUnverfied/SendMail';
import ReusableTable from '../../components/AntTable/AntTable';

const AdminUsers = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState('verified');

  // Menu items for the dropdown
  const menuItems = [
    {
      type: 'group', // Group label for better categorization
      label: 'Status',
      children: [
        {
          key: 'verified',
          icon: <MdOutlineVerifiedUser className='text-xl' />,
          label: (
            <div
              className='flex items-center cursor-pointer'
              onClick={() => setFilter('verified')}
            >
              Verified
            </div>
          ),
        },
        {
          key: 'unverified',
          icon: <GoUnverified className='text-xl' />,
          label: (
            <div
              className='flex items-center cursor-pointer'
              onClick={() => setFilter('unverified')}
            >
              Unverified
            </div>
          ),
        },
      ],
    },
  ];

  // Fetch users based on filter
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res = await AdminListUsers(filter);
        setUsers(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [refresh, filter]);

  // Delete user
  const deleteUser = async (userid) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this user?'
    );

    if (confirmDelete) {
      setLoading(true);

      const data = { userid };

      try {
        const promise = AdminDeleteUser(data);
        const res = await toast.promise(promise, {
          loading: 'Deleting User...',
          success: 'User Deleted Successfully',
          error: (error) => `Error: ${error.response.data.data}`,
        });

        if (res.data.success === true) {
          setRefresh(!refresh);
        }
      } catch (error) {
        toast.error(error.response.data.data);
      }
    }
  };

  // Table columns definition
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobilenumber',
    },
    {
      title: 'Action',
      render: (text, user) => (
        <button onClick={() => deleteUser(user._id)} className='h-6 w-6'>
          <img src='/images/icons/delete.png' alt='Delete' />
        </button>
      ),
    },
  ];

  return (
    <div>
      <div className='flex'>
        <div className='z-20'>
          <Sidebar />
        </div>
        <Toaster />
        <div className='pl-20 flex flex-col w-full'>
          <div className='mx-4'>
            <AdminNavbar />
            <hr className='mb-3' />
          </div>
          <div className='headline '>
            <div className='heading mx-4 pb-20 z-10 '>
              <div className='head flex justify-between'>
                <p className='text-2xl font-semibold'>
                  <span className='capitalize ml-0'>{filter} </span>Users
                </p>
                <div className='filterbyfeature flex space-x-5 items-center justify-center'>
                  {filter == 'unverified' && <SendMail />}
                  <Dropdown
                    overlay={
                      <Menu
                        items={menuItems}
                        selectedKeys={[filter]} // Highlight the selected item
                      />
                    }
                    trigger={['click']}
                  >
                    <button className='px-4 py-1 flex items-center gap-2 rounded-md border border-gray-300 bg-gray-50 text-gray-900 hover:bg-gray-100 focus:ring-2 focus:ring-[#C0A04C] focus:outline-none justify-center transition-colors duration-150 ease-in-out dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600'>
                      <span className='ml-0 text-gray-600'>Status</span>
                      <CiFilter className='text-lg text-gray-600' />
                    </button>
                  </Dropdown>
                </div>
              </div>

              <hr className='mt-3 mb-3' />

              <ReusableTable
                columns={columns}
                dataSource={users.data || []}
                loading={loading}
                actionText='Reload'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
