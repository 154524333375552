import React, { useState, useEffect } from 'react';
import Navbar from '../../../components/shared/Navbar/Navbar';
import Tabbar from '../../../components/shared/Tabbar/Tabbar';
import FavoriteCard from '../../../components/Cards/FavoriteCard';
import Footer from '../../../components/shared/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import {
  addToFavorites,
  ClientEventDetailsApi,
  ClientFavEventApi,
} from '../../../http/index'; // Assume this API fetches event details
import BottomNav from '../../../components/shared/BottomNav/BottomNav';
import SkeletonCard from '../../../components/shared/skeletons/SkeletonCard';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import { useSelector } from 'react-redux';
import useNavigation from '../../../hooks/useNavigation';

const Favorites = () => {
  document.title = 'Favorites';
  const navigation = useNavigation();
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleAddToFavorites = async (event) => {
    await addToFavorites({ eventid: event._id });
  };

  const syncLocalFavoritesToServer = async () => {
    try {
      const localFavorites =
        JSON.parse(localStorage.getItem('favorites')) || [];
      if (localFavorites.length > 0) {
        localFavorites.map((event) => {
          handleAddToFavorites(event);
        });
        localStorage.removeItem('favorites'); // Clear local storage after syncing
      }
    } catch (error) {
      console.error('Error syncing local favorites to the server:', error);
    }
  };

  const fetchFavoritesFromLocalStorage = async () => {
    try {
      const favoriteEventIds =
        JSON.parse(localStorage.getItem('favorites')) || [];
      if (favoriteEventIds.length > 0) {
        // Fetch event details for each ID
        const eventDetails = await Promise.all(
          favoriteEventIds.map(async (eventId) => {
            try {
              const { data } = await ClientEventDetailsApi(eventId); // API call to fetch event details
              console.log('this is data', data);
              return data.data.eventDetails;
            } catch (error) {
              console.error(`Error fetching event ${eventId}:`, error);
              return null;
            }
          })
        );
        setFavorites(eventDetails.filter((event) => event !== null));
      }
    } catch (error) {
      console.error('Error fetching favorites from localStorage:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchdata = async () => {
    try {
      // syncLocalFavoritesToServer();
      const response = await ClientFavEventApi();
      setFavorites(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAuth) {
      fetchFavoritesFromLocalStorage();
    } else {
      fetchdata();
    }
  }, []);

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className='!h-screen dark:bg-[#2c2c2c] dark:text-white'>
      <div className='dark:bg-[#2c2c2c] dark:text-white appmargine'>
        <Navbar />
        <Tabbar />

        <section className='relative md:mr-48 md:ml-48 mt-5 l:ml-6 l:mr-6 mx-3'>
          <div className='ml-3 hidden md:flex align-middle items-center'>
            <button onClick={navigation} className=' mt-1'>
              <img
                className='h-14 w-14'
                src='/images/icons/back-button.png'
                alt=''
              />
            </button>
            <p className='text-2xl font-bold'>Favorites</p>
          </div>

          {!loading && favorites.length === 0 && (
            <div className='grid grid-flow-row grid-cols-1 '>
              <div className='h-80 flex flex-col justify-center items-center'>
                <img
                  className='dark:hidden flex h-40 aspect-square'
                  src='/images/assets/logo-main.png'
                  alt=''
                />
                <img
                  className='hidden dark:flex h-40 aspect-square'
                  src='/images/logo/logo-main-light.png'
                  alt=''
                />
                <span className='text-md text-center mt-1 font-semibold text-gray-700 dark:text-gray-300'>
                  No favorites yet. <br />
                  Tap the heart to add to your favorite list!
                </span>
              </div>
            </div>
          )}

          <div className='mx-2 grid grid-flow-row gap:6 md:gap-8 text-neutral-600 grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4'>
            {loading ? (
              <>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </>
            ) : (
              favorites.map((event) => (
                <FavoriteCard key={event._id} data={event} />
              ))
            )}
          </div>

          <ScrollToTop />
        </section>

        <div className=''>
          <Footer />
        </div>
      </div>
      <div>
        <BottomNav />
      </div>
    </div>
  );
};

export default Favorites;
