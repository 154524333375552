import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DarkModeToggle from "../../DarkModeToggle/DarkModeToggle";

const VendorMenu = ({
  isOpen,
  toggleDropdown,
  dropdownRef,
  funVendorLogout,
  userLogout,
}) => {
  const navigate = useNavigate();

  return (
    <div className="space-x-2 flex align-middle items-center justify-center">
      <div className="flex justify-center">
        <DarkModeToggle />
      </div>
      <button className="relative" onClick={toggleDropdown}>
        <img
          className="m-1 h-10"
          src="/images/icons/navprofile.png"
          alt="Profile"
        />
        {isOpen && (
          <div
            className="origin-top-left right-0 absolute mt-0 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 w-52 z-50 dark:bg-[#2c2c2c]"
            ref={dropdownRef}
          >
            <div className="px-3 flex flex-col">
              <ul
                className="py-2 text-base text-black dark:text-gray-200"
                aria-labelledby="doubleDropdownButton"
              >
                <Link
                  to={
                    window.location.pathname.includes("/vendor/")
                      ? `/vendor/my-account`
                      : `/my-account`
                  }
                >
                  <a
                    href="#"
                    className="flex block px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white align-middle items-center"
                  >
                    <img
                      src="/images/icons/user.svg"
                      className="dark:hidden flex h-4 mr-2 fill-current text-gray-900 dark:text-white"
                      alt="User"
                    />
                    <img
                      src="/images/icons/user-light.svg"
                      className="dark:flex hidden h-4 mr-2 fill-current text-gray-900 dark:text-white"
                      alt="User Light"
                    />
                    Profile
                  </a>
                </Link>
                <button
                  onClick={() => navigate("/vendor/hostedevents")}
                  className="flex w-full block px-4 py-2 hover:bg-gray-100 text-left dark:hover:bg-gray-600 dark:hover:text-white align-middle items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-calendar-check-2"
                  >
                    <path d="M8 2v4" />
                    <path d="M16 2v4" />
                    <path d="M21 14V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8" />
                    <path d="M3 10h18" />
                    <path d="m16 20 2 2 4-4" />
                  </svg>
                  <p className="ml-2 ">Hosted Events</p>
                </button>
                <button
                  onClick={funVendorLogout}
                  className="flex w-full block px-4 py-2 hover:bg-gray-100 text-left dark:hover:bg-gray-600 dark:hover:text-white align-middle items-center"
                >
                  <img
                    src="/images/icons/log-out-light.svg"
                    className="dark:flex hidden h-4 mr-2"
                    alt="Logout"
                  />
                  <img
                    src="/images/icons/log-out.svg"
                    className="dark:hidden flex h-4 mr-2"
                    alt="Logout"
                  />
                  Logout
                </button>
              </ul>
            </div>
          </div>
        )}
      </button>
    </div>
  );
};

export default VendorMenu;
