import { useNavigate } from 'react-router-dom';

const useNavigation = () => {
  const navigate = useNavigate();

  const conditionalNavigate = () => {
    console.log(window.history);
    if (window.history.length > 2) {
      // There is a previous path
      navigate(-1);
    } else {
      // No previous path, navigate to home
      navigate('/');
    }
  };

  return conditionalNavigate;
};

export default useNavigation;
