import React, { useState } from 'react';
import { Table } from 'antd';
import './anttable.css'

const AntTable = ({ columns, dataSource, onAction, actionText = 'Reload' }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const start = () => {
    setLoading(true);
    setTimeout(() => {
      if (onAction) onAction(selectedRowKeys);
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys); // Properly update selectedRowKeys
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.disabled, // Example condition to disable checkboxes on some rows
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className='table-container'>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        rowKey='_id' // Ensure each row has a unique key (e.g., '_id')
        pagination={true} // Optional: Disable pagination if not needed
      />
    </div>
  );
};

export default AntTable;
