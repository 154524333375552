import React, { useState, useEffect } from 'react'
import { AdminGetRecentEvents, getPopupModal, AdminGetUnverifiedVendors, AdminGetAllUsers, getEventsForAdmin, getOffersForAdmin, AdminStats, AdminVerifyVendor } from '../../http/index'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment'
import AdminNavbar from '../../components/shared/Navbar/AdminNavbar';
import PopupBox from '../../components/PopupBox/PopupBox';
import { popup_id } from '../../utils/Data';
import EditPopupMessage from '../../components/EditEventModal/EditPopupMessage';
const AdminHome = () => {
    document.title = "Admin Dashboard"

    const navigate = useNavigate()
    const [vendors, setVendors] = useState([])
    const [users, setUsers] = useState([])
    const [response, setResponse] = useState([])
    const [stats, setStats] = useState({})
    const [loading, setLoading] = useState(false)
    const [offers, setOffers] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [popupData, setPopupData] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const [showPopEditModal, setShowPopEditModal] = useState(false)

    const handleClose = () => {
        setShowPopEditModal(false)
    }

    useEffect(() => {
        let tokenExpiredHandled = false; // Flag to track whether session expired error has been handled

        const handleError = (error) => {
            setLoading(false);
            if (error.response && error.response.status === 401) {
                if (!tokenExpiredHandled) {
                    tokenExpiredHandled = true; // Set the flag to true to indicate the error has been handled
                    toast.error("session expired. Please log in again.");
                    navigate('/vendor/login')
                }
            } else {
                toast.error(error.response?.data?.data || "An error occurred.");
            }
        };



        const fetchdata = async () => {
            setLoading(true);
            try {
                const res = await AdminGetUnverifiedVendors();
                setVendors(res.data);
            } catch (error) {
                handleError(error);
            }
        };

        const fetchUsers = async () => {
            setLoading(true);
            try {
                const res = await AdminGetAllUsers();
                setUsers(res.data);
            } catch (error) {
                handleError(error);
            }
        };

        const fetchevents = async () => {
            setLoading(true);
            try {
                const res = await AdminGetRecentEvents();
                setResponse(res.data);
            } catch (error) {
                handleError(error);
            }
        };

        const fetchOffers = async () => {
            setLoading(true);
            try {
                const res = await getOffersForAdmin();
                setOffers(res.data);
            } catch (error) {
                handleError(error);
            }
        };

        const fetchCount = async () => {
            setLoading(true);
            try {
                const res = await AdminStats();
                setStats(res.data);
            } catch (error) {
                handleError(error);
            }
        };

        // Execute all the fetch functions
        Promise.all([fetchdata(), fetchUsers(), fetchevents(), fetchOffers(), fetchCount()])
            .then(() => setLoading(false))
            .catch(() => setLoading(false)); // Handle errors collectively after all requests are completed

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getPopupModal(popup_id)
                setPopupData(data.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [showPopEditModal])

    const verifyUsers = async (vendorid) => {
        setLoading(true);

        try {
            const offerdata = {
                vendorid: vendorid
            };

            // Use toast.promise to display a promise-based toast
            await toast.promise(AdminVerifyVendor(offerdata), {
                loading: 'Verifying Vendor...',
                success: 'Vendor Verified Successfully',
                error: (error) => `Error: ${error.response.data.data}`,
            });

            // Refresh or update the UI as needed after successful verification
            setRefresh(!refresh);
        } catch (error) {
            toast.error(error.response.data.data);
        }
    };



    return (
        <>
            <div className='mx-10 dark:bg-white dark:text-black'>
                <Toaster />
                <div className='flex flex-col w-full'>

                    <AdminNavbar />

                    <div className="headline ">
                        <div className="heading">
                            <span className="text-2xl font-semibold text-lg">Dashboard</span>
                            <hr className='mt-3 mb-3' />
                            <div className='flex justify-between '>
                                <Link to='/admin/users'>
                                    <div className="m-3 cards flex justify-between md:flex-row flex-col">
                                        <div className="p-4 hover:bg-slate-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                                            <div className="flex items-start justify-between">
                                                <div className="flex flex-col space-y-2">
                                                    <span className="text-gray-400">Total Users</span>
                                                    {
                                                        stats.data != null && (
                                                            <span className="text-lg font-semibold text-xl">
                                                                {stats.data.users}
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                                <div className="ml-2">
                                                    <img className="h-14" src="/images/icons/users.png" alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Link>

                                <Link to='/admin/vendors'>
                                    <div className="m-3 cards flex justify-between md:flex-row flex-col">
                                        <div className="p-4 hover:bg-slate-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                                            <div className="flex items-start justify-between">
                                                <div className="flex flex-col space-y-2">
                                                    <span className="text-gray-400">Total Vendors</span>
                                                    {
                                                        stats.data != null && (
                                                            <span className="text-xl font-semibold text-xl">{stats.data.vendors}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="ml-2">
                                                    <img className="h-14" src="/images/icons/vendor.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to='/admin/events'>
                                    <div className="m-3 cards flex justify-between md:flex-row flex-col">
                                        <div className="p-4 hover:bg-slate-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                                            <div className="flex items-start justify-between">
                                                <div className="flex flex-col space-y-2">
                                                    <span className="text-gray-400">Total Events</span>
                                                    {
                                                        stats.data != null && (
                                                            <span className="text-xl font-semibold text-xl">{stats.data.events}</span>
                                                        )
                                                    }

                                                </div>
                                                <div className="ml-2">
                                                    <img className="h-14" src="/images/icons/events.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to='/admin/offers'>
                                    <div className="m-3 cards flex justify-between md:flex-row flex-col">
                                        <div className="p-4 hover:bg-slate-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                                            <div className="flex items-start justify-between">
                                                <div className="flex flex-col space-y-2">
                                                    <span className="text-gray-400">Total Offers</span>
                                                    {
                                                        stats.data != null && (
                                                            <span className="text-xl font-semibold text-xl">{stats.data.offers}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="ml-2">
                                                    <img className="h-14" src="/images/icons/offers.png" alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Link>

                                <Link to='/admin/Categories'>
                                    <div className="m-3 cards flex justify-between md:flex-row flex-col">
                                        <div className="p-4 hover:bg-slate-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                                            <div className="flex items-start justify-between">
                                                <div className="flex flex-col space-y-2">
                                                    <span className="text-gray-400">Total Categories</span>
                                                    {
                                                        stats.data != null && (
                                                            <span className="text-xl font-semibold text-xl">{stats.data.category}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="ml-2">
                                                    <img className="h-14" src="/images/icons/categories.png" alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Link>

                                <Link to='/admin/venue'>
                                    <div className="m-3 cards flex justify-between md:flex-row flex-col">
                                        <div className="p-4 hover:bg-slate-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                                            <div className="flex items-start justify-between">
                                                <div className="flex flex-col space-y-2">
                                                    <span className="text-gray-400">Total Venues</span>
                                                    {
                                                        stats.data != null && (
                                                            <span className="text-xl font-semibold text-xl">{stats.data.venues}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="ml-2">
                                                    <img className="h-14" src="/images/icons/location.png" alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="maincontent ml-4 mr-4 mt-5">
                        <div className="1st row grid grid-cols-3 gap-10 ">
                            <div className='p-3 col-span-2 '>
                                <div className="recentOffers mt-4">
                                    <div className='table1 h-auto '>
                                        <div className="mb-2 title flex justify-between">
                                            <p className='font-semibold text-xl'>Recently Added Events</p>
                                            <Link to='/admin/events'>
                                                <button className='px-1.5 py-1 bg-blue-800 text-sm text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600'>show all</button>
                                            </Link>
                                        </div>
                                        <div className="overflow-x-auto">

                                            <div className="border border-1 shadow-md relative overflow-x-auto shadow-md sm:rounded-lg">
                                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3">
                                                                Event Name
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Vendor ID
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Date
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Venue
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Status
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    {
                                                        response.data == null
                                                            ?
                                                            <tbody>
                                                                <tr >
                                                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                        <div className="flex items-center justify-between">
                                                                            <div>
                                                                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 w-96">
                                                                        <div className="flex items-center justify-between">
                                                                            <div>
                                                                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                        <div className="flex items-center justify-between">
                                                                            <div>
                                                                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                        <div className="flex items-center justify-between">
                                                                            <div>
                                                                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                            </div>
                                                                        </div>                                                        </td>
                                                                    <td className="space-x-3 px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                        <div className="flex items-center justify-between">
                                                                            <div>
                                                                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            :
                                                            <tbody>
                                                                {
                                                                    response.data.slice(0, 7).map((event) => (
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                                <Link to={`/admin/event/${event.slug}`}>
                                                                                    {event.title}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                                <Link to={`/admin/event/${event.slug}`}>
                                                                                    {event.vendorid.firstname || null}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                                <Link to={`/admin/event/${event.slug}`}>
                                                                                    {moment(event.createdAt).format("DD-MM-YYYY")}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                                <Link to={`/admin/event/${event.slug}`}>
                                                                                    {event.location?.name || ""}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                                <Link to={`/admin/event/${event.slug}`}>
                                                                                    {event.verified
                                                                                        ?
                                                                                        <span className='bg-green-100 text-green-800 text-xs font-medium ml-0 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300'>
                                                                                            Verified
                                                                                        </span>
                                                                                        :
                                                                                        <span className='bg-red-100 text-red-800 text-xs font-medium ml-0 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300'>
                                                                                            Unverified
                                                                                        </span>
                                                                                    }
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='p-3 w-full flex flex-col items-end '>
                                <div className='w-full flex justify-between'>
                                    <h2 className='text-xl font-bold'>Pop up box</h2>
                                    <button className='hover:bg-slate-100 rounded cursor-pointer p-2' onClick={() => setShowPopEditModal(true)}>
                                        <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9.95401 2.2106C11.2876 1.93144 12.6807 1.92263 14.0449 2.20785C14.2219 3.3674 14.9048 4.43892 15.9997 5.07103C17.0945 5.70313 18.364 5.75884 19.4566 5.3323C20.3858 6.37118 21.0747 7.58203 21.4997 8.87652C20.5852 9.60958 19.9997 10.736 19.9997 11.9992C19.9997 13.2632 20.5859 14.3902 21.5013 15.1232C21.29 15.7636 21.0104 16.3922 20.6599 16.9992C20.3094 17.6063 19.9049 18.1627 19.4559 18.6659C18.3634 18.2396 17.0943 18.2955 15.9997 18.9274C14.9057 19.559 14.223 20.6294 14.0453 21.7879C12.7118 22.067 11.3187 22.0758 9.95443 21.7906C9.77748 20.6311 9.09451 19.5595 7.99967 18.9274C6.90484 18.2953 5.63539 18.2396 4.54272 18.6662C3.61357 17.6273 2.92466 16.4164 2.49964 15.1219C3.41412 14.3889 3.99968 13.2624 3.99968 11.9992C3.99968 10.7353 3.41344 9.60827 2.49805 8.87524C2.70933 8.23482 2.98894 7.60629 3.33942 6.99923C3.68991 6.39217 4.09443 5.83576 4.54341 5.33257C5.63593 5.75881 6.90507 5.703 7.99967 5.07103C9.09364 4.43942 9.7764 3.3691 9.95401 2.2106ZM11.9997 14.9992C13.6565 14.9992 14.9997 13.6561 14.9997 11.9992C14.9997 10.3424 13.6565 8.99923 11.9997 8.99923C10.3428 8.99923 8.99967 10.3424 8.99967 11.9992C8.99967 13.6561 10.3428 14.9992 11.9997 14.9992Z"></path></svg>
                                    </button>
                                </div>
                                <div className='mt-2 border border-1 rounded-md'>
                                    {popupData != null && (
                                        <PopupBox width={`auto`} data={popupData} />
                                    )}
                                </div>
                                {
                                    showPopEditModal && (
                                        <div>
                                            <EditPopupMessage onClose={handleClose} />
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className='mt-10 grid grid-cols-2 gap-8'>
                            <div className='table1 h-96 shadow-md'>
                                <div className="title mb-2 flex justify-between">
                                    <p className='font-semibold text-lg'>Unverified Vendors</p>
                                    <Link to='/admin/vendors'>
                                        <button className='px-1.5 py-1 bg-blue-800 text-sm text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600'>show all</button>
                                    </Link>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-6 w-64 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Email
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    First Name
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Last Name
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        {
                                            vendors.data == null
                                                ?
                                                <tbody>
                                                    <tr >
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 w-96">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>                                                        </td>
                                                        <td className="space-x-3 px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>


                                                </tbody>
                                                :
                                                <tbody>
                                                    {
                                                        vendors.data.map((vendor) => (
                                                            <tr>
                                                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                    {vendor.email}
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                    {vendor.firstname}
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                    {vendor.lastname}
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                    <button onClick={() => verifyUsers(vendor._id)} className="text-white rounded-md h-6 w-6">
                                                                        <img src="/images/icons/done.png" alt="" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                        }

                                    </table>
                                </div>
                            </div>

                            <div className='table1 h-96 shadow-md'>
                                <div className="title mb-2 flex justify-between">
                                    <p className='font-semibold text-lg'>Recently Registered Users</p>
                                    <Link to='/admin/users'>
                                        <button className='px-1.5 py-1 bg-blue-800 text-sm text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600'>show all</button>
                                    </Link>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-6 w-64 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Email
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    First Name
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Last Name
                                                </th>
                                                {/* <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                        Action
                                                    </th> */}
                                            </tr>
                                        </thead>
                                        {
                                            users.data == null
                                                ?
                                                <tbody>
                                                    <tr >
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 w-96">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                            <div className="flex items-center justify-between">
                                                                <div>
                                                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                                </div>
                                                            </div>                                                        </td>
                                                    </tr>
                                                </tbody>
                                                :
                                                <tbody>
                                                    {
                                                        users.data.map((user) => (
                                                            <tr>
                                                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                    {user.email}
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                    {user.firstname}
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                    {user.lastname}
                                                                </td>
                                                                {/* <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                                                                <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                                                                    Verify
                                                                </button>
                                                            </td> */}
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default AdminHome