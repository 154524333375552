import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { setAuth } from "../../../store/authSlice";
import { ClientLogin, AppClientAppleSignIn, ClientGoogleLogin, googleLogin, facebookLogin, AppClientGoogleLogin } from "../../../http/index"
import { hasGrantedAllScopesGoogle, useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import ResendDialogueBox from '../../../components/ResendDialogueBox/ResendDialogueBox';
import { processApiQueue } from '../../../utils/Apiqueue';

const Login = () => {

    document.title = 'Login'
    const emailInputRef = useRef(null);
    const location = useLocation();
    const from = location.state?.from || '/';

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    const responseFacebook = async (response) => {
        try {
            setLoading(true);

            // Assuming facebookLogin is a function that returns a promise
            const res = await toast.promise(facebookLogin(response), {
                loading: 'Logging in...', // Message to display while the promise is pending
                success: 'Login successful!', // Message to display on success
            });

            console.log('success', res);
            setLoading(false);
            // console.log('this is logged in details', res.data)
            dispatch(setAuth(res.data));
            const prevLocation = '/';
            navigate(from, { replace: true });
        } catch (error) {
            console.error('Error during login:', error);
            setLoading(false);
            toast.error('Login failed. Please try again.');
        }
    };

    const loginToWebsite = async (accessToken, refreshToken) => {
        const data = {
            accessToken: accessToken,
            refreshToken: refreshToken
        };
        const res = await toast.promise(AppClientGoogleLogin(data), {
            loading: 'Logging in...', // Message to display while the promise is pending
            success: 'Login successful!', // Message to display on success
        });

        if (window.isNative) {
            // Define the action and payload
            const action = 'refresh'; // or any other action you want to handle
            // Convert phone number to a string if it's not already

            // Construct the message object
            const message = JSON.stringify({
                action,
            });

            // Send the message to the WebView
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(message);
            }
        }
        dispatch(setAuth(res.data));
        navigate('/')
    };

    useEffect(() => {
        // Listen for the 'tokensReady' event
        window.addEventListener('tokensReady', () => {
            if (window.appleId || window.authorizationCode || window.appleEmail) {
                loginToApple(window.appleId, window.authorizationCode, window.appleEmail)
            } else {
                loginToWebsite(window.accessToken, window.refreshToken);
            }
        });

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('tokensReady', () => { });
        };
    }, []);

    const loginToApple = async (appleId, authorizationCode, appleEmail) => {
        const data = {
            appleId: appleId,
            authorizationCode: authorizationCode,
            appleEmail: appleEmail
        };
        const res = await toast.promise(AppClientAppleSignIn(data), {
            loading: 'Logging in...', // Message to display while the promise is pending
            success: 'Login successful!', // Message to display on success
        });

        if (window.isNative) {
            // Define the action and payload
            const action = 'refresh'; // or any other action you want to handle
            // Convert phone number to a string if it's not already

            // Construct the message object
            const message = JSON.stringify({
                action,
            });

            // Send the message to the WebView
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(message);
            }
        }
        dispatch(setAuth(res.data));
        navigate('/')
    }

    const decideLogin = () => {
        if (window.isNative) {
            if (window.isNative) {
                // Define the action and payload
                const action = 'startGoogleAuth'; // or any other action you want to handle
                // Convert phone number to a string if it's not already

                // Construct the message object
                const message = JSON.stringify({
                    action,
                });

                // Send the message to the WebView

                window.ReactNativeWebView.postMessage(message);
            }
        }
        else {
            login()
        }
    }

    const signInWithApple = async () => {
        if (window.isNative) {
            // Define the action and payload
            const action = 'startAppleAuth'; // or any other action you want to handle
            // Convert phone number to a string if it's not already

            // Construct the message object
            const message = JSON.stringify({
                action,
            });

            // Send the message to the WebView

            window.ReactNativeWebView.postMessage(message);
        }
    }

    const googleLogin = async (codeResponse) => {
        console.log(codeResponse)
        try {
            setLoading(true);

            // Assuming facebookLogin is a function that returns a promise
            const res = await toast.promise(ClientGoogleLogin(codeResponse), {
                loading: 'Logging in...', // Message to display while the promise is pending
                success: 'Login successful!', // Message to display on success
            });

            console.log('success', res);
            setLoading(false);
            // console.log('this is logged in details', res.data)
            dispatch(setAuth(res.data));
            const prevLocation = '/';
            navigate(prevLocation);
        } catch (error) {
            console.error('Error during login:', error);
            setLoading(false);
            toast.error('Login failed. Please try again.');
        }
    }

    const scopes = ['profile', 'email'];
    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => { googleLogin(tokenResponse) },
        onError: (error) => { console.log('Login Failed:', error) },
        // scope: 'https://www.googleapis.com/auth/calendar',
        flow: 'auth-code',
    });

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState([]);
    const [showVerificationPopup, setShowVerificationPopup] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function submit(e) {

        e.preventDefault()

        if (!email || !password) {
            return toast.error("email and password is mandatory")
        }
        setLoading(true)
        try {
            const res = await ClientLogin({ email, password })
            console.log("success", res)
            setLoading(false)
            // console.log("this is logged in details",res.data)
            dispatch(setAuth(res.data));
            await processApiQueue()
            navigate(from, { replace: true });
        } catch (error) {
            setLoading(false)
            console.log(error)
            if (error.response.status == 400 && error.response.data.data == "Kindly verify your account for signing in") {
                setShowVerificationPopup(true)
            }
            toast.error(error.response.data.data)
        }
    }

    useEffect(() => {
        // Focus on the email input field when the component mounts
        emailInputRef.current.focus();
    }, []);

    return (
        <section className="dark:bg-[#2c2c2c] dark:text-white relative h-screen bg-no-repeat bg-center md:bg-object-scale-down bg-[url('https://muscat-media.nyc3.cdn.digitaloceanspaces.com/c82f6ed8a0d3d7c90476f041d3b78da6.jpeg')] md:bg-gray-400 md:bg-blend-multiply ">
            <button className='absolute top-10 left-10'>
                <img onClick={() => navigate(-1)} src="/images/icons/login-back.svg" alt="" />
            </button>
            <Toaster />
            <section className="flex flex-col space-y-2 justify-center items-center h-screen md:mt-0 mt-0 m-10">
                <div className="logo image mb-3">
                    <img className='h-6 md:h-12' src="/images/logo/login-logo.png" alt="" />
                </div>

                <form onSubmit={(e) => submit(e)} className="max-w-sm max-h-72 h-auto w-full bg:white dark:bg-[#2c2c2c] dark:text-white dark:border-0 bg-clip-padding backdrop-filter    backdrop-blur-sm bg-opacity-20 md:bg-white rounded-xl p-4 space-y-3 border border-white">
                    <div className="mb-4">
                        <h2 className="text-xl font-bold dark:text-white text-white md:text-black">Welcome Back</h2>
                    </div>
                    <div>
                        <input className="w-full p-2 text-sm bg-white md:bg-gray-100 focus:outline-none dark:text-white dark:bg-[#454545] dark:placeholder:text-white dark:border-0 ring-0 dark:focus:outline-0 focus:outline-none border border-gray-200 rounded-md text-gray-600"
                            value={email} ref={emailInputRef} onChange={(e) => setEmail(e.target.value)}
                            type="text" placeholder="Email" />
                    </div>
                    <div className="relative">
                        <input
                            className="w-full p-2 text-sm bg-white md:bg-gray-100 dark:text-white dark:bg-[#454545] dark:placeholder:text-white dark:border-0 ring-0 dark:focus:outline-0 focus:outline-none border border-gray-200 rounded-md text-gray-600"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500 dark:text-gray-400"
                        >
                            {showPassword ? (
                                <i class="ri-eye-line"></i>
                            ) : (
                                <i class="ri-eye-off-line"></i>
                            )}
                        </button>
                    </div>
                    <div className='flex justify-items-end justify-end'>

                        <Link to='/reset' className="justify-self-end text-sm font-medium dark:text-white text-white md:text-black underline" href="#">Forgot password?</Link>
                    </div>
                    <div>
                        {
                            loading
                                ?
                                <button className="flex justify-center items-center w-full  p-2 bg-[#C0A04C] hover:bg-[#A48533] rounded-md text-sm font-bold text-gray-50 transition duration-200">
                                    <img className='h-6' src="/images/icons/button-loading.svg" alt="" />
                                </button>
                                :
                                <button className="w-full p-2 bg-[#C0A04C] hover:bg-[#A48533] rounded-md text-sm font-bold text-gray-50 transition duration-200" type='submit'>Login</button>

                        }
                    </div>
                    {
                        !window.isNative && (
                            <div onClick={() => navigate('/vendor/login')} className="cursor-pointer w-full text-center text-sm font-semibold ">
                                <span className='underline underline-offset-1 text-center'>I'm a vendor</span>
                            </div>
                        )
                    }
                </form>

                <div className="max-w-sm w-full rounded-lg p-4 space-y-4 flex flex-col justify-center methods">
                    <button
                        onClick={() => decideLogin()}
                        type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                        <img className='h-6' src="/images/icons/google-icon.svg" alt="" />
                        <span className='mx-auto text-center'>
                            Continue with Google
                        </span>
                    </button>

                    {
                        window.isNative && (
                            <button
                                onClick={() => signInWithApple()}
                                type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                                <img className='h-6' src="/images/icons/apple.png" alt="" />
                                <span className='mx-auto text-center'>
                                    Continue with Apple
                                </span>
                            </button>
                        )
                    }

                    {
                        !window.isNative && (
                            <FacebookLogin
                                appId="1346318836020986"
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                render={renderProps => (
                                    <button
                                        onClick={renderProps.onClick}
                                        type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                                        <img className='h-6' src="/images/icons/facebook-icon.svg" alt="" />
                                        <span className='mx-auto text-center'>
                                            Continue with Facebook
                                        </span>
                                    </button>
                                )
                                }
                            />
                        )
                    }

                    <button
                        onClick={() => navigate("/sign-up")}
                        type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                        <img className='h-5' src="/images/icons/email-colour.png" alt="" />
                        <span className='mx-auto text-center'>
                            Continue with Sign up
                        </span>
                    </button>
                </div>
            </section>
            {
                showVerificationPopup && (
                    <div>
                        <div>
                            <div className='calendar-overlay'>
                                <div className='px-3 relative text-black'>
                                    <div className='absolute top-0 right-0'>
                                        <button onClick={() => setShowVerificationPopup(false)} className='text-white hover:underline'><img className=' bg-white rounded-full h-7 cursor-pointer' src="/images/icons/cancel-icon-new.png" alt="" /></button>
                                    </div>
                                    <ResendDialogueBox onClose={() => setShowVerificationPopup(false)} email={email} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </section>

    )
}

export default Login
