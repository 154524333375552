import React, { useState, useEffect } from "react";
import Navbar from "../../../components/shared/Navbar/Navbar";
import PastPurchaseCard from "../../../components/Cards/PastPurchaseCard";
import Footer from "../../../components/shared/Footer/Footer";
import { Link } from "react-router-dom";
import {
  VendorHomeApi,
  VendorUnverifiedEvents,
  GetVendorUnreadNotification,
} from "../../../http/index";
import AddEventModal from "../../../components/EditEventModal/AddEventModal";
import AddOfferModal from "../../../components/EditEventModal/AddOfferModal";
import VendorUnverifedCard from "../../../components/Cards/VendorUnverifedCard";
const VendorHome = () => {
  document.title = "Vendor ~ Home";

  const [response, setReponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [showAddOffer, setShowAddOffer] = useState(false);
  const [unverifiedEvents, setUnverifiedEvents] = useState({});
  const [eventsLoading, setEventsLoading] = useState(false);
  const closeModal = () => {
    const confirm = window.confirm(
      "Do you really want to go back? You will lose your progress."
    );
    if (confirm) {
      setShowAddEvent(false);
      document.body.style.overflow = "auto"; // Reset overflow
    }
  };

  const handleClick = () => {
    setShowAddEvent(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling
  };
  const closeOfferModal = () => {
    const confirm = window.confirm(
      "Do you really want to go back? You will lose your progress."
    );
    if (confirm) {
      setShowAddOffer(false);
    }
  };

  const handleOfferClick = () => {
    setShowAddOffer(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Use Promise.all to fetch data concurrently
        const [homeData, eventData] = await Promise.all([
          VendorHomeApi(),
          VendorUnverifiedEvents(),
        ]);

        setReponse(homeData.data); // Access data within each response object
        setUnverifiedEvents(eventData.data);
      } catch (error) {
        console.error("Error fetching data:", error); // Handle error more appropriately
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Cleanup function for overflow style
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="dark:bg-[#2c2c2c] dark:text-white">
      <div className="z-50 sticky top-0 shadow-lg">
        <Navbar />
      </div>
      <div className="flex justify-center items-center align-middle mt-5">
        <section className="w-full sm:mx-5 md:mx-5 md:w-9/12 xl:w-8/12 2xl:w-7/12">
          <div className="grid md:grid-cols-2 header">
            <div className="drop-shadow-xl">
              <div className="dark:bg-[#454545] h-56 m-3 rounded-lg bg-white flex flex-col space-y-2 justify-center items-center align-middle pb-6 ">
                <img
                  className=""
                  src="/images/assets/vendoraddevent.png"
                  alt=""
                />
                <button
                  type="button"
                  onClick={handleClick}
                  className="w-52 text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white font-medium rounded-lg px-4 py-2 text-center mr-3 md:mr-0  dark:bg-[#C0A04C] dark:hover:bg-[#A48533] flex align-middle items-center justify-center "
                >
                  <img className="h-3 mr-3" src="/images/icons/+.svg" alt="" />
                  <p className="font-bold leading-5">Add Event / Offer</p>
                </button>
              </div>
            </div>
            <div className="drop-shadow-xl">
              <div className="dark:bg-[#454545]  h-56 m-3 rounded-lg bg-white flex flex-col space-y-2 justify-center items-center align-middle pb-4 ">
                <img
                  className="h-36"
                  src="/images/assets/vendoraddoffer.png"
                  alt=""
                />
                <button
                  type="button"
                  onClick={handleOfferClick}
                  className="w-52 text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white font-medium rounded-lg px-4 py-2 text-center mr-3 md:mr-0  dark:bg-[#C0A04C] dark:hover:bg-[#A48533] flex align-middle items-center justify-center "
                >
                  <img className="h-3 mr-3" src="/images/icons/+.svg" alt="" />
                  <p className="font-bold leading-5">Add Voucher</p>
                </button>
              </div>
            </div>
          </div>

          <>
            {response.data == null || unverifiedEvents.data == null ? (
              <div className="h-100 w-full flex justify-center ">
                <div className="relative flex justify-center items-center">
                  <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#C0A04C]"></div>
                  <img
                    src="/images/logo/logo-main.png"
                    className="dark:hidden flex h-16 aspect-square"
                  />
                  <img
                    src="/images/logo/logo-main-light.png"
                    className="hidden dark:flex h-16 aspect-square"
                  />
                </div>
              </div>
            ) : (
              <>
                {response.data.ongoingEvents.length != 0 && (
                  <div className="mt-5 flex flex-col">
                    <div className="flex justify-between items-center">
                      <span className="font-bold text-2xl">Ongoing Events</span>
                      <Link
                        className="flex justify-end text-right"
                        to="/vendor/hostedevents"
                      >
                        {response.data.ongoingEvents != 0 ? (
                          <span className="dark:text-white text-right underline underline-gray-500 mr-3 cursor-pointer text-sm text-gray-500 dark:gray-100 ">
                            view all
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </Link>
                    </div>

                    <div className="grid grid-flow-row gap:8 md:mx-3 md:gap-3 text-neutral-600 grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
                      {response.data.ongoingEvents.length != 0 ? (
                        response.data.ongoingEvents.slice(0, 3).map((event) => (
                          <Link to={`/vendor/event/${event._id}`}>
                            <div
                              key={event._id}
                              className="w-full mx-2 flex-shrink-0"
                            >
                              <PastPurchaseCard data={event} />
                            </div>
                          </Link>
                        ))
                      ) : (
                        <div>no ongoing events..</div>
                      )}
                    </div>
                  </div>
                )}

                {response.data.ongoingOffers.length != 0 && (
                  <div className="mt-5 flex flex-col">
                    <div className="flex ">
                      <span className="font-bold text-2xl">Ongoing Offers</span>
                      <span className="text-right underline underline-gray-500 mr-3 cursor-pointer text-sm text-gray-500">
                        view all
                      </span>
                    </div>
                    <div className="pl-2 flex overflow-x-auto ">
                      {response.data.ongoingOffers.map((offer) => (
                        <>
                          <div key={offer._id} className="mx-2 flex-shrink-0">
                            <Link to={`/vendor/event/${offer._id}`}>
                              <PastPurchaseCard data={offer} />
                            </Link>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                )}

                {unverifiedEvents.data.length !== 0 && (
                  <div className="mt-5 flex flex-col">
                    <div className="mx-4 flex items-center justify-between">
                      <p className="font-bold  text-2xl ">
                        Unverified Lisitings
                      </p>
                      <Link
                        className="flex justify-end  text-right"
                        to="/vendor/hostedevents"
                      >
                        {eventsLoading ? (
                          <></>
                        ) : (
                          <>
                            {unverifiedEvents.data.length != 0 ? (
                              <span className="dark:text-white text-right underline underline-gray-500 mr-3 cursor-pointer text-sm text-gray-500">
                                view all
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </>
                        )}
                      </Link>
                    </div>
                    <div className="grid grid-flow-row gap:8 md:mx-3 text-neutral-600 grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
                      {eventsLoading ? (
                        <></>
                      ) : (
                        unverifiedEvents.data.slice(0, 3).map((offer) => (
                          <div key={offer._id} className="flex-shrink-0">
                            {/* <Link to={`/vendor/event/${offer._id}`}> */}
                            <VendorUnverifedCard data={offer} />
                            {/* </Link> */}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </section>
        {showAddEvent && (
          <div className="fixed inset-0 flex justify-center z-50 overflow-auto bg-[#FFFFFF] bg-opacity-20 backdrop-blur-sm">
            <div className="relative rounded-lg ">
              <AddEventModal
                setIsLoading={setIsLoading}
                isOpen={showAddEvent}
                onClose={closeModal}
              />
              {/* Close button */}
              {!isLoading && (
                <button
                  onClick={closeModal}
                  className="absolute top-3 -right-5 m-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  <img src="/images/icons/cancel-icon.png" alt="" />
                </button>
              )}
            </div>
          </div>
        )}

        {showAddOffer && (
          <div className="fixed inset-0 flex justify-center z-50 overflow-auto bg-[#FFFFFF] bg-opacity-20 backdrop-blur-sm">
            <div className="relative rounded-lg ">
              <AddOfferModal isOpen={showAddOffer} onClose={closeOfferModal} />
              {/* Close button */}
              <button
                onClick={closeOfferModal}
                className="absolute top-3 -right-5 m-2 text-gray-600 hover:text-gray-800 focus:outline-none"
              >
                <img src="/images/icons/cancel-icon.png" alt="" />
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default VendorHome;
