"use client";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GetAllCategory } from "../../../../http/index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { cn } from "../../../../lib/utils.ts";

export const CategoryCard = React.memo(({ category, index, hovered, setHovered }) => (
    <div
        onMouseEnter={() => setHovered(index)}
        onMouseLeave={() => setHovered(null)}
        className={cn(
            "rounded-lg relative bg-gray-100 dark:bg-neutral-900 overflow-hidden h-40 md:h-60 w-full transition-all duration-300 ease-out",
            hovered !== null && hovered !== index && "scale-[0.98]"
        )}
    >
        <Link to={`/category/${category.categoryURL}`}>
            <LazyLoadImage
                src={category.photo}
                alt={category.name}
                className="object-cover h-40 md:h-60 w-full rounded-md"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-50"></div>
            <span className="absolute bottom-0 left-0 text-white p-2 font-bold">
                {category.name}
            </span>
        </Link>
    </div>
));

CategoryCard.displayName = "CategoryCard";

export function WhereTo() {
    const [showAll, setShowAll] = useState(false);
    const [categories, setCategories] = useState([]);
    const [hovered, setHovered] = useState(null);
    const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const res = await GetAllCategory();
        const categoriesToRemove = [
          '67440126163b6b564138d634',
          '67469afe163b6b56413c602d',
          '6729f86fb2035187befa6a6b',
        ];
        const filterCategories = res.data.data.filter(
          (category) => !categoriesToRemove.includes(category._id)
        );
        setCategories(filterCategories);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  let visibleCategories = [];

  if (categories) {
    if (showAll) {
      visibleCategories = categories;
    } else {
      visibleCategories = categories.slice(0, 6);
    }
  }

    return (
        <section className="flex justify-center items-center align-middle py-5">
            <section className="w-full lg:w-10/12 xl:w-9/12 2xl:w-7/12">
                {!loading && visibleCategories.length > 0 && (
                    <>
                        <div className="flex justify-between mx-5">
                            <span className="text-xl font-bold md:text-2xl">Where To?</span>
                        </div>
                        <div className="mx-5 grid grid-cols-2 md:grid-cols-3 gap-3 p-3">
                            {visibleCategories.map((category, index) => (
                                <CategoryCard
                                    key={category.categoryURL}
                                    category={category}
                                    index={index}
                                    hovered={hovered}
                                    setHovered={setHovered}
                                />
                            ))}
                        </div>
                    </>
                )}

                {!loading && visibleCategories.length > 0 && (
                    <div className="flex justify-end mx-5">
                        <button
                            className="dark:hover:bg-gray-500 hover:bg-slate-100 rounded-md py-2 px-3"
                            onClick={() => setShowAll(!showAll)}
                        >
                            <p className="font-medium underline underline-offset-1">
                                {showAll ? "View less" : "View more"}
                            </p>
                        </button>
                    </div>
                )}
            </section>
        </section>
    );
}

export default React.memo(WhereTo);
