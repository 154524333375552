import './navbar.css';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  clientLogout,
  vendorLogout,
  GetNotificationCount,
  GetAllCategory,
  GetVendorUnreadNotification,
} from '../../../http';
import VendorMenu from './Components/VendorMenu';
import UserMenu from './Components/UserMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../../store/authSlice';
import toast, { Toaster } from 'react-hot-toast';
import DarkModeToggle from '../DarkModeToggle/DarkModeToggle';
import queryString from 'query-string';
import useNavigation from '../../../hooks/useNavigation';

const cache = {
  data: null,
  timestamp: null,
};

const CACHE_DURATION = 1000 * 60 * 10; // 10 minutes in milliseconds

const Navbar = ({ searchQuery, setSearchQuery }) => {
  const navigation = useNavigation();
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [vendorUnread, setVendorUnread] = useState(false);
  const navigate = useNavigate();

  const handleCategoryClick = (categoryURL, hasSubCategories) => {
    // If the category has subcategories, toggle the expanded state
    if (hasSubCategories) {
      setExpandedCategory((prevCategory) =>
        prevCategory === categoryURL ? null : categoryURL
      );
    } else {
      navigate(`/category/${categoryURL}`);
    }
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isAccOpen, setIsAccOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [unread, setUnread] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const { isAuth, user } = useSelector((state) => state.auth);
  let { category, eventid } = useParams();

  // Event handlers
  const openDropdown = () => setDropdownOpen(true);
  const closeDropdown = () => setDropdownOpen(false);
  const handleSearchInput = (e) => setSearchQuery(e.target.value);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOnclick = () => {
    userLogout();
    showSidebar();
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const showSidebar = () => setSidebar(!sidebar);
  const onClick = () => setIsAccOpen(!isAccOpen);

  const userLogout = async () => {
    try {
      const { data } = await clientLogout();
      dispatch(setAuth(data));
      toast.success('logged out');
    } catch (error) {
      toast.error('Error while logging out the user');
    }
  };

  const funVendorLogout = async () => {
    try {
      const { data } = await vendorLogout();
      dispatch(setAuth(data));
      toast.success('logged out');
      navigate('/vendor/login');
    } catch (error) {
      toast.error('Error while logging out the user');
    }
  };

  const [categoryName, setCategoryName] = useState(`${category}`);
  const [showCustomCategory, setShowCustomCategory] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);

      const now = new Date().getTime();
      if (false) {
        // Use cached data if it is still valid

        setCategories(cache.data.data);
        handleCategoryPath(cache.data);
        setLoading(false);
        return;
      } else {
        try {
          // Fetch data from API
          const res = await GetAllCategory();
          cache.data = res; // Update cache with new data
          cache.timestamp = now; // Update timestamp
          const categoriesToRemove = [
            '67440126163b6b564138d634',
            '67469afe163b6b56413c602d',
            '6729f86fb2035187befa6a6b',
          ];
          const filterCategories = res.data.data.filter(
            (category) => !categoriesToRemove.includes(category._id)
          );
          setCategories(filterCategories);
          //   setCategories(res.data);
          handleCategoryPath(res.data);
        } catch (error) {
          console.error('Failed to fetch categories', error);
        } finally {
          setLoading(false);
        }
      }
    };

    const handleCategoryPath = (data) => {
      console.log('this is category path data', data);
      if (window.location.pathname.includes('/favorites')) {
        setShowCustomCategory(true);
        setCategoryName('Favorites');
      } else if (window.location.pathname.includes('/category/')) {
        setShowCustomCategory(true);
        const categoryURL = window.location.pathname.split('/').pop();
        const assignedCategory = data.data.data.find(
          (category) => category.categoryURL === categoryURL
        );
        if (assignedCategory) {
          setCategoryName(assignedCategory.name);
        } else {
          setCategoryName(categoryURL);
        }
      } else if (window.location.pathname.includes('/events/')) {
        setShowCustomCategory(true);
        setCategoryName('Description');
      } else if (window.location.pathname.includes('/bookticket/')) {
        setShowCustomCategory(true);
        setCategoryName('Book Your Seat');
      } else if (window.location.pathname.includes('/venue/')) {
        setShowCustomCategory(true);
        setCategoryName('Venue Description');
      } else if (window.location.pathname.includes('/pastpurchase')) {
        setShowCustomCategory(true);
        setCategoryName('Past Purchased');
      } else if (window.location.pathname.includes('/ticketstatus/')) {
        setShowCustomCategory(true);
        setCategoryName('Ticket Status');
      }
    };

    fetchCategory();
  }, []);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const subcategory = queryString.parse(location.subcategory);

  useEffect(() => {
    const getNotificationCountVendor = async () => {
      try {
        const notificationCount = await GetVendorUnreadNotification();
        // console.log(notificationCount)
        if (notificationCount.data.data > 0) {
          setVendorUnread(true);
        }
      } catch {}
    };

    if (isAuth == true && user.role == 'vendor') {
      getNotificationCountVendor();
    }
  }, [isAuth, user]);

  useEffect(() => {
    // toast.success(window.localStorage.getItem("expoToken"))
    const getNotificationCountFunction = async () => {
      try {
        const notificationCount = await GetNotificationCount({
          expoToken: window.expoToken,
        });
        console.log(notificationCount);
        if (notificationCount.data.data > 0) {
          setUnread(true);
        }
      } catch (error) {
        // console.log('something haapped')
      }
    };

    if (window.isNative || (isAuth == true && user.type == 'user')) {
      getNotificationCountFunction();
    }
  }, [window.expotoken]);

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling on the main content area
    } else {
      document.body.style.overflow = 'auto'; // Allow scrolling on the main content area
    }
  }, [sidebar]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='relative z-50'>
      <div className='relative dark:bg-[#2c2c2c] dark:text-white top-0 z-40  flex justify-center items-center align-middle'>
        <div className='bg-white border-gray-200 dark:bg-[#2c2c2c] w-full md:w-full sm:mx-5 md:mx-5 md:w-10/12 xl:w-9/12 2xl:w-7/12'>
          <Toaster />
          {/* <div className="z-0">
            <img
              className="left-0 top-0 z-0 absolute sm:w-[100%]  h-[90%] md:-top-20 md:w-[100%]"
              src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmasmobnavleft1.png"
              alt=""
            />
            <img
              className="right-0 top-0 z-0 absolute sm:w-[100%] h-[90%] md:-top-20 md:w-[100%]"
              src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmasmobnavright1.png"
              alt=""
            />
          </div> */}
          {/* <div className="absolute top-0 right-0 z-0 overflow-hidden">
          <img
            className="hidden xl:flex"
            src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmasnavrightnew.png"
            alt=""
          />
        </div> */}
          {/* <div className='absolute top-0 left-0 z-0 overflow-hidden'>
            <img
              className='hidden xl:flex'
              src='https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmasnavleftnew.png'
              alt=''
            />
          </div> */}
          <div className='relative max-w-screen-xl flex flex-wrap items-center justify-between mx-auto pl-4 pr-4 py-4 md:pt-2 md:pb-1 shadow-md md:shadow-none'>
            {showCustomCategory ? (
              <div className='flex align-middle'>
                <button className='menu-bars lg:hidden ' onClick={navigation}>
                  <img
                    className='flex dark:hidden'
                    src='/images/icons/back-arrow.svg'
                    alt=''
                  />
                  <img
                    className='dark:flex hidden'
                    src='/images/icons/back-arrow-light.svg'
                    alt=''
                  />
                </button>
                <span className='capitalize lg:hidden text-xl font-bold'>
                  {queryParams.subcategory ? (
                    <>{decodeURIComponent(queryParams.subcategory)}</>
                  ) : (
                    decodeURIComponent(categoryName)
                  )}
                </span>
                <Link to='/' className='hidden flex lg:flex items-center'>
                  <img
                    src='/images/logo/logo-main.webp'
                    className='dark:hidden flex h-10 md:mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/logo/logo.webp'
                    className='dark:hidden flex h-6 mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/logo/logo-main-light.png'
                    className='dark:flex hidden h-10 md:mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/icons/logo-light.svg'
                    className='dark:flex hidden flex h-6 mr-3'
                    alt='MWT Logo'
                  />
                </Link>
              </div>
            ) : (
              <div className='flex align-middle'>
                <button className='menu-bars lg:hidden' onClick={showSidebar}>
                  <img
                    className='flex dark:hidden'
                    src='/images/icons/menu.svg'
                    alt=''
                  />
                  <img
                    className='dark:flex hidden'
                    src='/images/icons/menu-light.svg'
                    alt=''
                  />
                </button>

                <Link to='/' className='hidden flex lg:flex items-center'>
                  <img
                    src='/images/logo/logo-main.webp'
                    className='dark:hidden flex h-10 md:mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/logo/logo.webp'
                    className='dark:hidden flex h-6 mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/logo/logo-main-light.png'
                    className='dark:flex hidden h-10 md:mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/icons/logo-light.svg'
                    className='dark:flex hidden flex h-6 mr-3'
                    alt='MWT Logo'
                  />
                </Link>
              </div>
            )}

            {!showCustomCategory ? (
              <div className='flex lg:hidden'>
                <Link to='/' className='flex items-center'>
                  {/* <img className='left-0 top-0 z-0 absolute sm:w-[100%]  h-[90%] md:-top-20 md:w-[100%]' src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmasmobnavleft1.png" alt="" /> */}
                  <img
                    src='/images/logo/logo-main.png'
                    className='z-50 dark:hidden flex h-10 md:mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/logo/logo-main-light.png'
                    className='z-50 hidden dark:flex h-10 md:mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/logo/logo.png'
                    className='z-50 dark:hidden flex h-6 mr-3'
                    alt='MWT Logo'
                  />
                  <img
                    src='/images/icons/logo-light.svg'
                    className='z-50 dark:flex hidden flex h-6 mr-3'
                    alt='MWT Logo'
                  />
                  {/* <img className='right-0 top-0 z-0 absolute sm:w-[100%] h-[90%] md:-top-20 md:w-[100%]' src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmasmobnavright1.png" alt="" /> */}
                </Link>
              </div>
            ) : (
              <></>
            )}

            <div className='hidden lg:flex md:order-2 space-x-2'>
              {isAuth ? (
                user.role === 'vendor' &&
                window.location.pathname.includes('vendor') ? (
                  <VendorMenu
                    isOpen={isOpen}
                    toggleDropdown={toggleDropdown}
                    dropdownRef={dropdownRef}
                    funVendorLogout={funVendorLogout}
                    userLogout={userLogout}
                  />
                ) : user.role != 'vendor' && user.role != 'admin' ? (
                  <UserMenu
                    isOpen={isOpen}
                    toggleDropdown={toggleDropdown}
                    dropdownRef={dropdownRef}
                    userLogout={userLogout}
                  />
                ) : (
                  <div className='space-x-2 flex align-middle items-center justify-center'>
                    <div className='flex justify-center'>
                      <DarkModeToggle />
                    </div>
                    <Link to='/login'>
                      <button
                        type='button'
                        className='text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-[#A48533] dark:focus:ring-0'
                      >
                        Log in
                      </button>
                    </Link>
                    <Link to='/sign-up'>
                      <button
                        type='button'
                        className='dark:text-white text-[#C0A04C] border border-1 border-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 dark:text-white md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-[#A48533] dark:focus:ring-0'
                      >
                        Sign up
                      </button>
                    </Link>
                  </div>
                )
              ) : (
                <div className='space-x-2 flex align-middle items-center justify-center'>
                  <div className='flex justify-center'>
                    <DarkModeToggle />
                  </div>
                  <Link to='/login'>
                    <button
                      type='button'
                      className='text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-[#A48533] dark:focus:ring-0'
                    >
                      Log in
                    </button>
                  </Link>
                  <Link to='/sign-up'>
                    <button
                      type='button'
                      className='dark:text-white text-[#C0A04C] border border-1 border-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-[#A48533] dark:focus:ring-0'
                    >
                      Sign up
                    </button>
                  </Link>
                </div>
              )}
            </div>

            {window.location.pathname == '/vendor/notification' ||
            window.location.pathname == '/vendor/home' ||
            window.location.pathname == '/vendor/hostedevents' ||
            window.location.pathname == `/vendor/event/${eventid}` ||
            window.location.pathname == '/vendor/my-account' ||
            window.location.pathname == '/vendor/helpcenter' ||
            window.location.pathname == `/vendor/${eventid}/bookedtickets` ? (
              <div
                className='items-center md:space-x-10 justify-between hidden w-full lg:flex md:w-auto md:order-1'
                id='navbar-cta'
              >
                <div>
                  {window.location.pathname == '/vendor/hostedevents' ? (
                    <div className='hidden md:block search'>
                      <div className=''>
                        <div className='relative mt-1'>
                          <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
                            <svg
                              className='w-5 h-5 text-gray-500 dark:text-gray-400'
                              fill='currentColor'
                              viewBox='0 0 20 20'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                clipRule='evenodd'
                              ></path>
                            </svg>
                          </div>

                          <input
                            value={searchQuery}
                            onChange={handleSearchInput}
                            type='text'
                            id='table-search'
                            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 md:w-52 pl-5 p-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                            placeholder='Search'
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <ul className='flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-[#2c2c2c] md:dark:bg-[#2c2c2c] dark:border-gray-700'>
                    <li>
                      <Link
                        to='/vendor/home'
                        className={`${
                          window.location.pathname == '/vendor/home'
                            ? 'font-bold'
                            : ''
                        }`}
                      >
                        <a
                          href='#'
                          className={`block py-2 pl-3 pr-4 md:p-0 `}
                          aria-current='page'
                        >
                          <span className='text-sm'>Home</span>
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/vendor/notification'
                        className={`${
                          window.location.pathname === '/vendor/notification'
                            ? 'font-bold'
                            : ''
                        }`}
                      >
                        <a
                          href='#'
                          className='relative block py-2 pl-3 pr-4 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700'
                        >
                          <span className='text-sm'>Notification</span>
                          {vendorUnread && (
                            <>
                              <span className='absolute top-0 -right-2 mt-1 mr-1 h-1 w-1 bg-red-600 rounded-full animate-ping'></span>
                              <span className='absolute top-0 -right-2 mt-1 mr-1 h-1 w-1 bg-red-600 rounded-full'></span>
                            </>
                          )}
                        </a>
                      </Link>
                    </li>
                    <li>
                      <a
                        href='https://app.chaport.com/#/chats'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:p-0  dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700'
                      >
                        <span className='text-sm'>Help Center</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div
                className='items-center justify-between hidden w-full lg:flex md:w-auto md:order-1'
                id='navbar-cta'
              >
                <ul className='flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-[#2c2c2c] md:dark:bg-[#2c2c2c] dark:border-gray-700'>
                  <li>
                    <Link
                      to='/'
                      className={`${
                        window.location.pathname == '/'
                          ? 'font-bold underline underline-offset-2 decoration-2 decoration-[#C0A04C]'
                          : ''
                      }`}
                    >
                      <a
                        href='#'
                        className={`block text-lg py-2 pl-3 pr-4 md:p-0 hover:font-bold md:dark:font-bold`}
                        aria-current='page'
                      >
                        Home
                      </a>
                    </Link>
                  </li>
                  <li>
                    <div
                      className={`${
                        window.location.pathname.includes('/category/')
                          ? 'font-bold underline underline-offset-2 decoration-2 decoration-[#C0A04C]'
                          : ''
                      }`}
                    >
                      <div className='dropdown-container relative'>
                        <a
                          href='#'
                          className='pointer-cursor hover-trigger block text-lg py-2 pl-3 pr-4 md:p-0 hover:font-bold md:dark:font-bold'
                          onMouseEnter={() => openDropdown()}
                          onMouseLeave={() => closeDropdown()}
                        >
                          Where to ?
                        </a>
                        {isDropdownOpen && (
                          <div
                            onMouseEnter={() => openDropdown()}
                            onMouseLeave={() => closeDropdown()}
                            className='py-2 z-50 dropdown absolute -right-2 w-52 h-auto bg-white rounded-md drop-shadow-md dark:bg-[#454545] dark:text-white '
                          >
                            {categories &&
                              categories.map((category, index) => (
                                <CategoryLink key={index} category={category} />
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link
                      to='/about'
                      className={`${
                        window.location.pathname == '/about'
                          ? 'font-bold underline underline-offset-2 decoration-2 decoration-[#C0A04C]'
                          : ''
                      }`}
                    >
                      <a
                        href='#'
                        className='block text-lg py-2 pl-3 pr-4 rounded hover:bg-gray-100 hover:font-bold md:hover:bg-transparent md:p-0 md:dark:hover:font-bold dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700'
                      >
                        About
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/contact'
                      className={`${
                        window.location.pathname == '/contact'
                          ? 'font-bold underline underline-offset-2 decoration-2 decoration-[#C0A04C]'
                          : ''
                      }`}
                    >
                      <a
                        href='#'
                        className='block text-lg py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:font-bold md:p-0 md:dark:hover:font-bold dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700'
                      >
                        Contact
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            <div className='flex lg:hidden space-x-2'>
              {category === 'events' ||
              category === 'eat' ||
              category === 'ladiesnight' ||
              category === 'weeklyoffers' ||
              category === 'thingstodo' ||
              category === 'staycation' ||
              category === 'poolnbeach' ||
              category === 'spaoffers' ||
              category === 'kidscorner' ? (
                <img
                  className='hidden'
                  src='/images/assets/search.svg'
                  alt=''
                />
              ) : (
                <></>
              )}
              {unread ? (
                <>
                  <div className='relative'>
                    <i
                      onClick={() => navigate('/user/notification')}
                      class='relative text-2xl dark:text-white ri-notification-4-fill'
                    ></i>
                    <div>
                      <span className='absolute top-0 right-0 mt-1 mr-1 h-2 w-2 bg-red-600 rounded-full animate-ping'></span>
                      <span className='absolute top-0 right-0 mt-1 mr-1 h-2 w-2 bg-red-600 rounded-full'></span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <i
                    onClick={() => navigate('/user/notification')}
                    class='text-2xl border-1 border-white dark:text-white ri-notification-4-fill'
                  ></i>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* sidebar for mobile view */}
      <nav
        className={
          sidebar
            ? 'nav-menu active dark:bg-[#2c2c2c] mb-10'
            : 'nav-menu dark:bg-[#2c2c2c] mb-10'
        }
      >
        <ul className='relative nav-menu-items dark:text-white overflow-y-auto'>
          <li className='dark:bg-[#2c2c2c] navbar-toggle flex justify-between justify-items-end'>
            <Link to='/' className='ml-2 flex md:flex items-center'>
              <img
                src='/images/logo/logo-main.png'
                className='dark:hidden flex h-10 md:mr-3'
                alt='MWT Logo'
              />
              <img
                src='/images/logo/logo-main-light.png'
                className='hidden dark:flex h-10 md:mr-3'
                alt='MWT Logo'
              />
              <img
                src='/images/logo/logo.png'
                className='dark:hidden flex h-6 mr-3'
                alt='MWT Logo'
              />
              <img
                src='/images/icons/logo-light.svg'
                className='dark:flex hidden flex h-6 mr-3'
                alt='MWT Logo'
              />
            </Link>

            <div className='flex  justify-end align-middle items-center'>
              <div className='flex flex-col mr-5'>
                <DarkModeToggle />
              </div>
              <div>
                <Link
                  to='#'
                  className='menu-bars dark:bg-[#2c2c2c]'
                  onClick={showSidebar}
                >
                  <img
                    className='mr-2 flex dark:hidden'
                    src='/images/icons/cancel-icon.png'
                    alt=''
                  />
                  <img
                    className='mr-2 dark:flex hidden'
                    src='/images/icons/cancel-icon-light.png'
                    alt=''
                  />
                </Link>
              </div>
            </div>
          </li>

          <div className='mt-3 flex flex-col space-y-8'>
            <Link
              to='/'
              className={`${
                window.location.pathname == '/' ? 'font-bold' : 'font-medium'
              }`}
              onClick={showSidebar}
            >
              <span className='ml-4 hover:border'>Home</span>
            </Link>

            <div className='ml-4'>
              <div onClick={onClick} className='cursor-pointer'>
                <div className='flex items-center space-x-2'>
                  <p className='font-medium'>Where to</p>
                  {isAccOpen ? (
                    <>
                      <img
                        className='dark:hidden flex h-2'
                        src='/images/icons/minus-light.svg'
                        alt='Minus Icon'
                      />
                      <img
                        className='dark:flex hidden h-2'
                        src='/images/icons/add-light.svg'
                        alt='Minus Icon'
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className='dark:hidden flex h-2'
                        src='/images/icons/add.svg'
                        alt='Add Icon'
                      />
                      <img
                        className='dark:flex hidden h-2'
                        src='/images/icons/minus-light.svg'
                        alt='Minus Icon'
                      />
                    </>
                  )}
                </div>
              </div>

              {isAccOpen && (
                <div className='flex flex-col space-y-4 pl-4 mt-3 transition-all duration-200'>
                  {categories &&
                    categories.length > 0 &&
                    categories.map((cat, index) => (
                      <div key={index}>
                        <div
                          onClick={() =>
                            handleCategoryClick(
                              cat.categoryURL,
                              cat.subCategories?.length > 0
                            )
                          }
                          className='flex items-center space-x-2 font-medium cursor-pointer'
                        >
                          <span>{cat.name}</span>
                          {cat.subCategories?.length > 0 && (
                            <>
                              <img
                                className='dark:hidden flex h-2'
                                src='/images/icons/add.svg'
                                alt='Add Icon'
                              />
                              <img
                                className='dark:flex hidden h-2'
                                src='/images/icons/minus-light.svg'
                                alt='Minus Icon'
                              />
                            </>
                          )}
                        </div>
                        {expandedCategory === cat.categoryURL &&
                          cat.subCategories?.length > 0 && (
                            <div className='flex flex-col pl-4 space-y-2'>
                              {cat.subCategories.map(
                                (subCategory, subIndex) => (
                                  <React.Fragment key={subIndex}>
                                    <Link
                                      to={`/category/${
                                        cat.categoryURL
                                      }?subcategory=${encodeURIComponent(
                                        subCategory.name
                                      )}`}
                                      className='ml-4 my-1 font-medium'
                                    >
                                      {subCategory.name}
                                    </Link>
                                    {subCategory.name === 'Dinner' &&
                                      Array.from({ length: 7 }, (_, i) => {
                                        const daysOfWeek = [
                                          'Sunday',
                                          'Monday',
                                          'Tuesday',
                                          'Wednesday',
                                          'Thursday',
                                          'Friday',
                                          'Saturday',
                                        ];
                                        const dayName = daysOfWeek[i];

                                        return (
                                          <div
                                            key={i}
                                            className='ml-4 cursor-pointer'
                                            onClick={() =>
                                              navigate(
                                                `/category/${
                                                  cat.categoryURL
                                                }?subcategory=${encodeURIComponent(
                                                  subCategory.name
                                                )}&day=${dayName}`
                                              )
                                            }
                                          >
                                            <span className='text-sm'>
                                              {dayName} Dinner
                                            </span>
                                          </div>
                                        );
                                      })}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          )}
                      </div>
                    ))}
                </div>
              )}
            </div>

            <Link
              to='/about'
              className={`${
                window.location.pathname == '/about'
                  ? 'font-bold'
                  : 'font-medium '
              }`}
            >
              <span className='ml-4 hover:border'>About</span>
            </Link>

            <Link
              to='/contact'
              className={`${
                window.location.pathname == '/contact'
                  ? 'font-bold'
                  : 'font-medium '
              }`}
            >
              <span className='ml-4 hover:border'>Contact</span>
            </Link>
            {isAuth && (
              <div className='NoStandalone flex flex-col space-y-8 '>
                <Link
                  to={window.isNative ? '/user/my-account' : '/my-account'}
                  className={`text-lg ${
                    window.location.pathname == '/my-account'
                      ? 'font-bold'
                      : 'font-medium '
                  }`}
                >
                  <span className='ml-4 hover:border'>Profile</span>
                </Link>
                <Link
                  Link
                  to='/favorites'
                  className={`text-base ${
                    window.location.pathname == '/favorites'
                      ? 'font-bold'
                      : 'font-medium '
                  }`}
                >
                  <span className='ml-4 hover:border'>Favorites</span>
                </Link>
                <Link
                  Link
                  to='/pastpurchase'
                  className={`text-base ${
                    window.location.pathname == '/pastpurchased'
                      ? 'font-bold'
                      : 'font-medium '
                  }`}
                >
                  <span className='ml-4 hover:border'>Past Purchased</span>
                </Link>
              </div>
            )}

            <div className='z-50  w-10/12 flex justify-center items-center bottom-20'>
              {isAuth ? (
                <>
                  {window.location.pathname.includes('/vendor/') ? (
                    <button
                      onClick={funVendorLogout}
                      type='button'
                      className='dark:bg-white dark:border-[#C0A04C] dark:text-[#C0A04C] ml-3 space-x-3 flex align-middle justify-center w-full border border-[#C0A04C] border-1.5 text-white hover:text-white bg-[#C0A04C] hover:bg-[#C0A04C] focus:ring-4 focus:outline-[#C0A04C] focus:[#A48533] font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0'
                    >
                      <img
                        className='h-5'
                        src='/images/icons/logout.svg'
                        alt=''
                      />
                      <p>Sign up</p>
                    </button>
                  ) : (
                    <button
                      onClick={handleOnclick}
                      type='button'
                      className='flex ml-3 space-x-3 flex align-middle justify-center w-full border border-[#C0A04C] border-1.5 text-white hover:text-white bg-[#C0A04C] hover:bg-[#C0A04C] focus:ring-4 focus:outline-[#C0A04C] focus:[#A48533] font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-white dark:focus:ring-blue-800'
                    >
                      <img
                        className='h-5'
                        src='/images/icons/logout.svg'
                        alt=''
                      />
                      <p>Log out</p>
                    </button>
                  )}
                </>
              ) : (
                <>
                  <div className='w-full flex flex-col justify-start space-y-2 ml-4 pb-20'>
                    <Link to='/login'>
                      <button
                        type='button'
                        className='w-10/12 text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-4 focus:outline-none focus:[#A48533] font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-white dark:focus:ring-blue-800'
                      >
                        Log in
                      </button>
                    </Link>
                    <Link to='/sign-up'>
                      <button
                        type='button'
                        className='w-10/12 border border-[#C0A04C] border-1.5 text-[#C0A04C] hover:text-white bg-white hover:bg-[#C0A04C] focus:ring-4 focus:outline-[#C0A04C] focus:[#A48533] font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 '
                      >
                        Sign up
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </ul>
      </nav>
    </div>
  );
};

const CategoryLink = ({ category }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDays, setShowDays] = useState(false);
  const [showWeekDays, setShowWeekDays] = useState(false);
  const openDropdown = () => setShowDropdown(true);
  const closeDropdown = () => setShowDropdown(false);

  return (
    <div className='' key={category.id}>
      {category.subCategories && category.subCategories.length > 0 ? (
        <>
          <div
            onClick={() => navigate(`/category/${category.categoryURL}`)}
            onMouseEnter={() => openDropdown()}
            onMouseLeave={() => closeDropdown()}
            className={`hover:bg-slate-100 dark:hover:bg-slate-500 dark:hover:text-white overflow-y-auto py-2 px-3 relative m-0 text-sm bg-left-bottom bg-gradient-to-r from-[#C0A04C] to-[#A48533] bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out cursor-pointer ${
              window.location.pathname === `/category/${category.categoryURL}`
                ? 'font-bold'
                : 'font-normal'
            }`}
          >
            <div className='flex items-center align-middle relative'>
              {category.name}{' '}
              <svg
                className='h-5 w-5'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
              >
                <path fill='none' d='M0 0h24v24H0z'></path>
                <path d='M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z'></path>
              </svg>
            </div>
          </div>

          {showDropdown && (
            <ul
              onMouseEnter={() => openDropdown()}
              onMouseLeave={() => closeDropdown()}
              className='absolute inset-y-0 py-2 left-48 z-50 w-52 bg-white dark:bg-[#454545] dark:text-white'
            >
              {category.subCategories.map((subcategory, index) => (
                <li key={index}>
                  <span
                    onClick={() =>
                      navigate(
                        `/category/${
                          category.categoryURL
                        }?subcategory=${encodeURIComponent(subcategory.name)}`
                      )
                    }
                    onMouseEnter={() => {
                      if (subcategory.name == 'Dinner') {
                        setShowDays(true);
                      }
                    }}
                    onMouseLeave={() => setShowDays(false)}
                    className={`flex align-middle items-center ml-0 block px-4 py-2 hover:bg-gray-100 dark:border-0 dark:hover:bg-gray-600 dark:hover:text-white text-sm font-normal cursor-pointer pointer-cursor`}
                    aria-current='page'
                  >
                    {subcategory.name}
                    {subcategory.name == 'Dinner' && (
                      <svg
                        className='h-5 w-5'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='currentColor'
                      >
                        <path fill='none' d='M0 0h24v24H0z'></path>
                        <path d='M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z'></path>
                      </svg>
                    )}
                  </span>

                  {subcategory.name === 'Dinner' && showDays && (
                    <ul
                      onMouseEnter={() => {
                        setShowDays(true);
                      }}
                      className='w-52  z-50 dropdown absolute inset-y-0 py-2 bg-white rounded-r-md dark:bg-[#454545] dark:text-white'
                      style={{ left: '100%' }}
                    >
                      {[
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                      ].map((day, index) => (
                        <li
                          onClick={() =>
                            navigate(
                              `/category/${
                                category.categoryURL
                              }?subcategory=${encodeURIComponent(
                                subcategory.name
                              )}&day=${day}`
                            )
                          }
                          key={index}
                        >
                          <a
                            href='#'
                            className={`ml-0 block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-sm font-normal cursor-pointer pointer-cursor`}
                            aria-current='page'
                          >
                            {day} Dinner
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <Link to={`/category/${category.categoryURL}`}>
          <div
            className={`hover:bg-slate-100 dark:hover:bg-slate-400 dark:hover:text-white  py-2 px-3 ml-0 text-sm bg-left-bottom bg-gradient-to-r from-[#C0A04C] to-[#A48533] bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out cursor-pointer ${
              window.location.pathname === `/category/${category.categoryURL}`
                ? 'font-bold'
                : 'font-normal'
            }`}
          >
            {category.name}
          </div>
        </Link>
      )}
    </div>
  );
};

export default Navbar;
