import React from 'react'
import { Link } from 'react-router-dom'

const TrendingCard = ({ data }) => {
    return (
        <>
            <Link to={`/events/${data.slug}`}>
                <div className="dark:bg-[#454545] flex items-center hover:shadow-xl rounded-md my-3 bg-slate-50">
                    <div className="image">
                        <img className='ml-0 aspect-square h-28 p-1.5 object-cover rounded-xl' src={`${data.displayPhoto}`} alt="" />
                    </div>
                    <div className="text-left w-[60%] overflow-hidden">
                        <div className="text-xs font-bold mb-1 trucate">
                            {data.title}
                        </div>
                        <p className="text-xs font-base mb-1">
                            {data.location?.name || ""}
                        </p>
                        <div className="text-xss font-light truncate mb-1">
                            {data.shortDescription}
                        </div>
                        <div className="text-xss font-light truncate">
                            {[...new Set(data.eventCategory.slice(0, 2).map(subcategory => subcategory.name))].join(', ')}
                        </div>
                    </div>

                </div>
            </Link>
        </>
    )
}

export default TrendingCard