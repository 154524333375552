import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { addToFavorites } from '../../http/index';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { enqueueApiRequest } from '../../utils/Apiqueue';

const UpcomingEventsCard = ({ event, showNumberBox, setNumber }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { user, isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    // Determine the initial liked state
    const isEventLiked = isAuth && event.likes.includes(user?._id);
    const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setIsLiked(isEventLiked || favorites.includes(event._id));
  }, [event.likes, user, isAuth, event._id]);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    // Add and clean up resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleFavoriteToggle = async () => {
    const updateLocalFavorites = (eventId) => {
      let favorites = JSON.parse(localStorage.getItem('favorites')) || [];
      const isCurrentlyLiked = favorites.includes(eventId);

      if (isCurrentlyLiked) {
        favorites = favorites.filter((id) => id !== eventId);
        toast.success('Removed from favorites');
      } else {
        favorites.push(eventId);
        toast.success('Added to favorites');
      }

      localStorage.setItem('favorites', JSON.stringify(favorites));
      return !isCurrentlyLiked;
    };

    try {
      if (window.isNative && !isAuth) {
        // Handle local storage logic for unauthenticated native users
        const newIsLiked = updateLocalFavorites(event._id);
        setIsLiked(newIsLiked);
        return;
      }

      // Handle API logic for authenticated users
      const { data } = await addToFavorites({ eventid: event._id });
      setIsLiked(!isLiked);
      toast.success(data.message);
    } catch (error) {
      console.error('Error updating favorites:', error);

      if (error.response?.status === 401) {
        enqueueApiRequest(() => handleFavoriteToggle());
        navigate('/login', { state: { from: window.location.pathname } });
      } else {
        toast.error('Failed to update favorites.');
      }
    }
  };

  const handleCalling = () => {
    const phoneNumber = String(event.phoneNo);

    if (window.isNative) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: 'call', phoneNumber })
      );
    } else if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      showNumberBox(true);
      setNumber(phoneNumber);
    }
  };

  const handleBooking = (e) => {
    e.stopPropagation();
    if (event.categories.some((cat) => cat.className)) {
      navigate(`/bookticket/${event.slug}`);
    } else {
      handleCalling();
    }
  };

  return (
    <div className='dark:text-white mt-2'>
      <div
        onClick={() => navigate(`/events/${event.slug}`)}
        className='cursor-pointer relative rounded-2xl mx-2 mb-2 bg-[#F3F3F3] dark:bg-[#454545] top-0 md:mt-5'
      >
        <img
          className='rounded-2xl object-cover aspect-square'
          src={event.displayPhoto}
          alt={event.title || 'Event Image'}
          loading='lazy'
        />
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleFavoriteToggle();
          }}
          aria-label={isLiked ? 'Remove from favorites' : 'Add to favorites'}
          className='absolute top-2 right-2 bg-white text-black rounded-full z-20 p-2'
        >
          <img
            src={
              isLiked
                ? '/images/icons/heart-fav.svg'
                : '/images/icons/heart.svg'
            }
            alt={isLiked ? 'Favorite' : 'Not Favorite'}
          />
        </button>
        <div className='flex flex-col p-2'>
          <p className='text-base mt-2 font-medium truncate'>{event.title}</p>
          <p className='text-base mt-2 font-medium truncate'>
            {event.location?.name || ' '}
          </p>
          <p className='mt-1 mb-1 text-sm font-light truncate'>
            {Array.from(
              new Set(event.eventCategory.map((sub) => sub.name))
            ).join(', ')}
          </p>
          <div className='flex items-center justify-between md:space-x-2'>
            <button
              onClick={handleBooking}
              type='button'
              className='text-white hover:bg-[#A48533] bg-[#C0A04C] focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 w-full'
            >
              {event.categories.some((cat) => cat.className)
                ? 'Book Now'
                : 'Call Now'}
            </button>
            <button
              type='button'
              className='hidden md:block text-white bg-[#C0A04C] hover:bg-[#A48533] font-medium rounded-lg text-sm px-4 py-2 w-full'
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventsCard;
