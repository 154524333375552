import { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useFormattedDate = (dateObject, showEndDate) => {
  const formattedDate = useMemo(() => {
    let showDateField = dayjs().format('ddd, DD MMMM YYYY');

    if (dateObject && dateObject.type !== 'dateRange') {
      const dayOrder = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      const days =
        dateObject.recurring?.days.map(
          (day) => day.charAt(0).toUpperCase() + day.slice(1)
        ) || [];

      // Sort days based on the predefined day order
      const sortedDays = days.sort(
        (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
      );

      if (sortedDays.length === 1) {
        showDateField = sortedDays[0];
      } else if (sortedDays.length === 2) {
        showDateField = sortedDays.join(' and ');
      } else if (sortedDays.length === 7) {
        showDateField = 'Daily';
      } else if (sortedDays.length > 2) {
        const lastDay = sortedDays.pop();
        showDateField = `${sortedDays.join(', ')}, and ${lastDay}`;
      }
    } else if (dateObject && dateObject.type === 'dateRange') {
      const startDate = dayjs(dateObject.dateRange?.startDate).startOf('day');
      const endDate = dayjs(dateObject.dateRange?.endDate).startOf('day');

      if (dateObject.dateRange?.endDate) {
        if (startDate.isSame(endDate)) {
          showDateField = startDate.format('ddd, DD MMMM YYYY');
        } else {
          showDateField = showEndDate
            ? `${startDate.format('D MMM')} to ${endDate.format('D MMM')}`
            : startDate.format('ddd, DD MMMM YYYY');
        }
      } else {
        const now = dayjs().tz('Asia/Muscat').startOf('day');
        const start = dayjs(startDate).tz('Asia/Muscat').startOf('day');

        if (start.isAfter(now)) {
          // If startDate is in the future, use startDate
        //   console.log()
          showDateField = start.format('ddd, DD MMMM YYYY');
        } else {
          // Otherwise, use today's date
          showDateField = now.format('ddd, DD MMMM YYYY');
        }
      }
    }

    return showDateField;
  }, [dateObject, showEndDate]);

  return formattedDate;
};
