import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function EventActions({ event, deleteEvent, verifyEvent, archiveEvent }) {
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null); // Close dropdown if click is outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = (eventId) => {
    setOpenDropdownId(openDropdownId === eventId ? null : eventId);
  };

  const isDropdownOpen = openDropdownId === event._id;

  return (
    <td className='flex justify-center px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900'>
      <div className='relative inline-block text-left'>
        <button
          type='button'
          className='bg-white shadow-md aspect-square h-6 rounded-full'
          onClick={() => toggleDropdown(event._id)}
          aria-expanded={isDropdownOpen}
          aria-haspopup='true'
        >
          <i className='ri-more-fill'></i>
        </button>

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className='z-20 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='menu-button'
            tabIndex='-1'
          >
            <div className='py-1' role='none'>
              <button
                onClick={() => {
                  deleteEvent(event._id);
                  setOpenDropdownId(null); // Close dropdown after action
                }}
                className='block px-4 py-2 text-sm text-gray-700 w-full text-left'
                role='menuitem'
                tabIndex='-1'
                id='menu-item-0'
              >
                <img
                  src='/images/icons/delete.png'
                  alt=''
                  className='inline-block h-6 w-6 mr-2'
                />
                Delete
              </button>

              <button
                onClick={() => {
                  archiveEvent(event._id);
                  setOpenDropdownId(null); // Close dropdown after action
                }}
                className='block px-4 py-2 text-sm text-gray-700 w-full text-left flex items-center'
                role='menuitem'
                tabIndex='-1'
                id='menu-item-3'
              >
                <div className='bg-gray-200 rounded-full aspect-square h-7 w-7 flex justify-center mr-2 items-center'>
                  <img
                    src='/images/icons/archive.svg'
                    alt=''
                    className='inline-block h-4 w-4 '
                  />
                </div>
                {event.archived ? 'Unarchive' : 'Archive'}
              </button>

              {!event.verified && (
                <button
                  onClick={() => {
                    verifyEvent(event._id);
                    setOpenDropdownId(null); // Close dropdown after action
                  }}
                  className='block px-4 py-2 text-sm text-gray-700 w-full text-left'
                  role='menuitem'
                  tabIndex='-1'
                  id='menu-item-1'
                >
                  <img
                    src='/images/icons/done.png'
                    alt=''
                    className='inline-block h-6 w-6 mr-2'
                  />
                  Verify
                </button>
              )}

              <button
                onClick={() => {
                  navigate(`/admin/event/${event.slug}`);
                  setOpenDropdownId(null); // Close dropdown after action
                }}
                className='block px-4 py-2 text-sm text-gray-700 w-full text-left'
                role='menuitem'
                tabIndex='-1'
                id='menu-item-2'
              >
                <img
                  src='/images/icons/eye.png'
                  alt=''
                  className='inline-block h-6 w-6 mr-2'
                />
                View
              </button>
            </div>
          </div>
        )}
      </div>
    </td>
  );
}

export default EventActions;
