import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import EventCard from '../../../../components/Cards/EventCard'
import SkeletonCard from '../../../../components/shared/skeletons/SkeletonCard'
import { GetTrendingEvents } from '../../../../http'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const EditorsPick = () => {
    const [editorpick, setEditorpick] = useState([])
    const [editorpickLoading, setEditorpickLoading] = useState(false)

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };

    const shuffleArray = (array) => {
        let shuffledArray = array.slice(); // Create a copy of the array
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
        }
        return shuffledArray;
    };

    let selectedEvents;

    useEffect(() => {
        const fetchdata = async () => {
            setEditorpickLoading(true)
            try {
                const trendingResponse = await GetTrendingEvents();
                selectedEvents = shuffleArray(trendingResponse.data.data);
                setEditorpick(selectedEvents);
                setEditorpickLoading(false)
            } catch (error) {
                console.log(error)
                setEditorpickLoading(false)
            }
        }

        fetchdata()
    }, []);

    return (
        <div>
            {
                editorpick === null || editorpick === undefined
                    ?
                    <>
                    </>
                    :
                    editorpickLoading
                        ?
                        <section className='flex justify-center items-center align-middle mt-5'>
                            <section className='w-full md:w-full sm:mx-5 lg:w-10/12 md:mx-5 md:w-10/12 xl:w-9/12 2xl:w-7/12'>
                                <div className='flex justify-between'>
                                    <div className="left"><span className='text-xl font-bold md:text-2xl md:font-[700]'>Trending in Muscat</span></div>
                                    <div className="right">
                                        <div className='flex justify-end w-30'>
                                            <Link className='w-30 hover:bg-slate-100 rounded-md py-2 px-3 flex justify-center align-middle items-center' to="/category/events">
                                                <p className='font-medium underline underline-offset-1  pr-2 text-sm font-medium '>view all</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='md:flex md:justify-start carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth  scrollbar-hide space-x-3'>
                                        <>
                                            <div className='h-30'>
                                                <SkeletonCard />
                                            </div>
                                        </>
                                    </div>

                                </div>
                                <div className='flex justify-end space-x-2 '>
                                    <Link to="/category/mwt'sfavorites">
                                        <p className='underline underline-offset-1 text-sm pr-2 '>view all</p>
                                    </Link>
                                </div>
                            </section>
                        </section>
                        :
                        editorpick.length === 0
                            ?
                            <>
                            </>
                            :
                            <section className='flex justify-center items-center align-middle mt-5'>
                                <section className='w-full md:w-full sm:mx-5 md:mx-5 lg:w-10/12 md:w-8.5/12 xl:w-8.5/12 2xl:w-7/12'>
                                    <div className='flex justify-between align-middle items-center'>
                                        <div className="left"><span className='text-xl font-bold md:text-2xl md:font-[700]'>Trending in Muscat</span></div>
                                        <div className="right">
                                            <div className='flex justify-end w-30'>
                                                <Link className='w-30 hover:bg-slate-100 rounded-md py-2 px-3 flex justify-center align-middle items-center' to="/category/trending%20events">
                                                    <p className='font-medium underline underline-offset-1  pr-2 text-sm font-medium '>view all</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='md:flex md:justify-start carousel px-4 flex items-center justify-start overflow-x-auto scroll-smooth  scrollbar-hide space-x-3'>
                                            {editorpickLoading
                                                ?
                                                <>
                                                    <div className='h-30'>
                                                        <SkeletonCard />
                                                    </div>
                                                </>
                                                : editorpick.length === 0
                                                    ?
                                                    <div className='flex justify-center'>
                                                        <img className='h-60' src="/images/assets/logo-main.png" alt="" />
                                                    </div>
                                                    :
                                                    <div className="w-full">
                                                        <Carousel responsive={responsive}>
                                                            {
                                                                editorpick.map((event) => (
                                                                        <EventCard data={event} />
                                                                ))
                                                            }
                                                        </Carousel>
                                                    </div>
                                            }
                                        </div>

                                    </div>

                                </section>
                            </section>
            }

        </div>
    )
}



export default EditorsPick