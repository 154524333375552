import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../../components/shared/Navbar/Navbar';
import BottomNav from '../../../components/shared/BottomNav/BottomNav';
import { GetAllCategory } from '../../../http';
import WhereTo from '../Home/Components/WhereTo';

const SearchPWA = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const res = await GetAllCategory();
        const categoriesToRemove = [
          '67440126163b6b564138d634',
          '67469afe163b6b56413c602d',
          '6729f86fb2035187befa6a6b',
        ];
        const filterCategories = res.data.data.filter(
          (category) => !categoriesToRemove.includes(category._id)
        );
        setCategories(filterCategories);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className='min-h-screen dark:bg-[#2c2c2c] dark:text-white '>
      <Navbar />
      <section className='dark:bg-[#2c2c2c]'>
        <div className='searchbar mt-5 relative ml-9 mr-9 shadow-xl rounded-xl'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              className='w-4 h-4 text-gray-500 dark:text-gray-400'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 20 20'
            >
              <path
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2'
                d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
              />
            </svg>
          </div>
          <input
            onChange={(e) => setQuery(e.target.value)}
            type='search'
            id='default-search'
            className='block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-xl bg-white dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
            placeholder='Search Where To'
            required
          />
          <button
            onClick={() =>
              navigate(`/category/events%20&%20offers?search=${query}`)
            }
            type='button'
            className='absolute top-0 right-0 h-full p-2.5 text-sm font-medium text-white bg-[#C0A04C] rounded-r-lg  focus:ring-4 focus:outline-none  dark:bg-[#C0A04C] dark:hover:bg-[#C0A04C]'
          >
            <img className='' src='/images/icons/home-search.svg' alt='' />
            <span className='sr-only'>Search</span>
          </button>
        </div>

        <div className='space-y-3 py-3 w-full event-list flex flex-col justify-center align-middle items-center'>
          <WhereTo />
        </div>
      </section>
      <div className='dark:bg-[#2c2c2c] pb-20'></div>
      <BottomNav />
    </div>
  );
};

export default SearchPWA;
